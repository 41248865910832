<template>
    <div>
        <fromSearch :object_='object_' @searchFun='getList' />
        <el-table :data="page.list" border v-loading='listLoading' height="350">
            <el-table-column prop="selection" :label="$t('currencySetNft.text8')" width="100" >
                <template #default="scope">
                    <el-checkbox size="large" @change="changeFun(scope.row)" v-model='checkItem'/>
                </template>
            </el-table-column>
            <el-table-column prop="chain" :label="$t('currencySetNft.text6')" width="180" />
            <el-table-column prop="protocol" :label="$t('currencySetNft.text7')" />
            <el-table-column prop="createTime" :label="$t('agreement.text5')" />
        </el-table>
        <pageCom :page='page' @sizeChange='sizeChange' @currentChange='currentChange'/>
    </div>
</template>
<script setup>
    import { ref,defineEmits,defineExpose } from 'vue'
    import mixins from '@/views/mixins/page'
    import { coin } from '@/const/from/nft/hotWallet'
    import fromSearch from '@/components/fromSearch.vue'
    import pageCom from '@/components/pageCom.vue'

    let pageObj = mixins(['getAgreementList'])
    const {page,listLoading,sizeChange,currentChange,getList,updateOtherParm} = pageObj
    
    const object_ = ref({});object_.value = coin
    const emit = defineEmits(['getCoin'])
    const checkItem = ref(false)

    const changeFun = (e)=>{
        emit('getCoin',{
            chain: e.chain,
            protocol: e.protocol
        })
    }
    const init_ = (e)=>{
        checkItem.value = false
        //当前数据塞到请求参数中
        updateOtherParm({protocolType: 2,...e})
        getList(e)
    }
    defineExpose({init_})
</script>

<style lang="scss" scoped>
</style>